// Entry point for the build script in your package.json
import Rails from '@rails/ujs';
import ahoy from "ahoy.js"

Rails.start();

document.addEventListener("DOMContentLoaded", function(){
  // This is needed to track ahoy visits since trackAll is depreceated
  ahoy.trackView();
  ahoy.trackClicks("a, button, input[type=submit]");
  ahoy.trackSubmits("form");
})